import React from 'react'
import { Layout } from '../Shared'
import { IrisImg, IrisCTALinkButton } from '../../components/Shared'
import AccentBorder from '../Shared/AccentBorder'
import './Integrations.global.scss'
import PropTypes from 'prop-types'
import { uppercaseKeyWords } from './utils'
import { chunk } from '../../utils/chunk'
import { calculatedView } from '../../utils/calculatedView'
import { getSignupUrl } from '../../utils/url'

const signupFormURL = getSignupUrl()

const FreeTrialButton = () => (
  <IrisCTALinkButton href={signupFormURL} cta="Try for free" />
)

const Introduction = props => {
  const {
    copy: {
      header,
      subHeader,
      integrationExplanationHeader,
      integrationExplanation,
    },
    images: {
      logos: { iris, integrationCompanyIcon },
      ideaMockups: { exportModel, vrIdea },
    },
    view,
  } = props

  const isDesktop = view === 'desktop'

  return (
    <div
      style={{
        paddingLeft: isDesktop ? 100 : 25,
        paddingRight: isDesktop ? 100 : 25,
      }}
    >
      <div
        style={{
          paddingBottom: isDesktop ? 140 : 40,
          display: 'flex',
          alignItems: 'center',
          ...(isDesktop
            ? { justifyContent: 'space-between' }
            : { flexDirection: 'column' }),
        }}
      >
        <div style={{ paddingRight: isDesktop ? 90 : 0 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingBottom: isDesktop ? 30 : 35,
            }}
          >
            <div style={{ paddingRight: 20 }}>
              <IrisImg className={`iris-icon`} file={iris} alt={`iris-icon`} />
            </div>
            <div style={{ paddingRight: 20, fontSize: 25 }}>+</div>
            <IrisImg
              className={`integration-company-icon`}
              file={integrationCompanyIcon}
              alt={`integration-company-icon`}
            />
          </div>
          <div style={{ paddingBottom: 15 }}>
            <p style={{ fontSize: 16 }}>{subHeader}</p>
          </div>
          <div
            style={{
              paddingBottom: isDesktop ? 40 : 25,
            }}
          >
            <p
              style={{
                fontSize: isDesktop ? 36 : 30,
              }}
            >
              {header}
            </p>
          </div>
          <div style={{ paddingBottom: isDesktop ? 0 : 20 }}>
            <FreeTrialButton
              buttonStyles={{
                paddingTop: 15,
                paddingBottom: 15,
                paddingRight: 25,
                paddingLeft: 25,
              }}
            />
          </div>
        </div>
        <div>
          {isDesktop && (
            <div className={`mockup-model-${view}`}>
              <IrisImg
                className={`mockup-model-${view}`}
                file={exportModel}
                alt={`mockup-model-${view}`}
              />
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          ...(isDesktop
            ? { justifyContent: 'space-between' }
            : { flexDirection: 'column' }),
          paddingBottom: 100,
        }}
      >
        <div
          className={`vr-idea-${view}`}
          style={{
            paddingRight: isDesktop ? 90 : 0,
            paddingBottom: isDesktop ? 0 : 50,
          }}
        >
          <IrisImg
            className={`vr-idea-${view}`}
            file={vrIdea}
            alt={`vr-idea`}
          />
        </div>
        <div>
          <div
            style={{
              paddingBottom: 20,
            }}
          >
            <p
              style={{
                fontSize: isDesktop ? 22 : 18,
                fontWeight: 'bold',
              }}
            >
              {integrationExplanationHeader}
            </p>
          </div>
          <p
            className="gray"
            style={{
              fontSize: isDesktop ? 18 : 16,
              lineHeight: isDesktop ? 2 : 1.5,
            }}
          >
            {integrationExplanation}
          </p>
        </div>
      </div>
    </div>
  )
}

const FeatureSummary = props => {
  const { sectionLinksHeader, exampleFeatureData, view } = props

  const isDesktop = view === 'desktop'

  const featureKeys = Object.keys(exampleFeatureData)
  /**
   * If 5 feature bullet points, list them in threes
   * Otherwise list them in pairs
   */
  const chunkSize = featureKeys.length > 4 ? 3 : 2

  return (
    <div
      style={{
        backgroundColor: 'black',
        color: 'white',
        paddingRight: isDesktop ? 0 : 25,
        paddingLeft: isDesktop ? 250 : 25,
        paddingBottom: isDesktop ? 115 : 65,
        paddingTop: isDesktop ? 115 : 65,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        maxWidth: 1000,
      }}
    >
      <div
        style={{
          paddingBottom: 40,
        }}
      >
        <p
          style={{
            ...(isDesktop
              ? { textAlign: 'center', fontSize: 32 }
              : { fontSize: 25, lineHeight: 1.6 }),
          }}
        >
          {sectionLinksHeader}
        </p>
      </div>
      <div
        style={{
          fontSize: 18,
          display: 'flex',
          lineHeight: 2,
          paddingLeft: isDesktop ? 20 : 0,
        }}
      >
        {!isDesktop && (
          <ul>
            {featureKeys.map(key => {
              return (
                <li key={`feature-${key}`}>
                  <a
                    href={`#${key}`}
                    style={{ color: 'white', textDecoration: 'none' }}
                  >
                    {exampleFeatureData[key].title}
                  </a>
                </li>
              )
            })}
          </ul>
        )}
        {isDesktop &&
          chunk(featureKeys, chunkSize).map((keys, idx) => {
            return (
              <ul
                key={`feature-column-${idx}`}
                style={{ paddingRight: idx === 0 ? 30 : 0, width: 400 }}
              >
                {keys.map((key, i) => (
                  <li key={`feature-link-${i}`}>
                    <a
                      href={`#${key}`}
                      style={{ color: 'white', textDecoration: 'none' }}
                    >
                      {exampleFeatureData[key].title}
                    </a>
                  </li>
                ))}
              </ul>
            )
          })}
      </div>
    </div>
  )
}

const FeatureDetail = props => {
  const {
    data: { image, title, description, descriptionList },
    view,
  } = props
  const isDesktop = view === 'desktop'

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <div style={{ ...(isDesktop ? {} : { paddingBottom: 10 }) }}>
        <AccentBorder color="purple" />
      </div>
      <div
        style={{
          paddingBottom: isDesktop ? 30 : 20,
        }}
      >
        <p style={{ fontSize: isDesktop ? 36 : 24 }}>
          {uppercaseKeyWords(title)}
        </p>
      </div>
      <div style={{ ...(isDesktop ? { display: 'flex' } : {}) }}>
        <div
          className={`feature-${view}`}
          style={{
            paddingRight: isDesktop ? 90 : 0,
            paddingBottom: isDesktop ? 0 : 35,
          }}
        >
          <IrisImg className={`feature-${view}`} file={image} alt={title} />
        </div>
        <div
          className="gray"
          style={{
            fontSize: isDesktop ? 20 : 16,
          }}
        >
          <p
            style={{
              ...(isDesktop ? {} : { lineHeight: 1.5, paddingBottom: 20 }),
            }}
          >
            {description}
          </p>
          {/**
            Navisworks has the only descriptionList
           */}
          {descriptionList && (
            <ul style={{ paddingTop: 10, marginLeft: -15 }}>
              {descriptionList.map((item, key) => {
                return <li key={`item-${key}`}>{item}</li>
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

const FeaturesExpanded = props => {
  const { exampleFeatureData, view } = props

  const isDesktop = view === 'desktop'
  const featureKeys = Object.keys(exampleFeatureData)

  return (
    <div
      style={{
        ...(isDesktop
          ? {
              paddingLeft: 100,
              paddingRight: 100,
              paddingTop: 0,
              paddingBottom: 100,
            }
          : {
              paddingLeft: 25,
              paddingRight: 25,
              marginTop: -40,
              paddingBottom: 70,
            }),
      }}
    >
      {Object.values(exampleFeatureData).map((data, idx) => {
        const featureKey = featureKeys[idx]
        return (
          <div key={`feature-${idx}`}>
            {/**
              Adding this padding -- and a negative margin top on mobile --
              will bring the first anchor link into the viewport
              which is how the rest of the other anchor links appear
             */}
            {idx === 0 && (
              <div
                id={featureKey}
                style={{ paddingTop: isDesktop ? 100 : 95 }}
              />
            )}

            {idx !== 0 && (
              <div id={featureKey}>
                <div
                  style={{ paddingTop: isDesktop ? 40 : 20, paddingBottom: 40 }}
                >
                  <hr className="divider" />
                </div>
              </div>
            )}
            <FeatureDetail data={data} view={view} />
          </div>
        )
      })}
    </div>
  )
}

const FinalSection = props => {
  const { finalCopy, view } = props

  const isDesktop = view === 'desktop'

  return (
    <div
      style={{
        backgroundColor: 'black',
        color: 'white',
        ...(isDesktop
          ? { padding: 130 }
          : {
              paddingLeft: 50,
              paddingRight: 50,
              paddingTop: 100,
              paddingBottom: 100,
              lineHeight: 2,
            }),
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      <div
        style={{
          paddingBottom: isDesktop ? 70 : 20,
        }}
      >
        <p
          style={{
            ...(isDesktop ? { fontSize: 32 } : { fontSize: 26, lineHeight: 2 }),
          }}
        >
          {finalCopy}
        </p>
      </div>
      <FreeTrialButton
        buttonStyles={{
          paddingTop: 25,
          paddingBottom: 25,
          paddingLeft: isDesktop ? 80 : 50,
          paddingRight: isDesktop ? 80 : 50,
          fontSize: 16,
        }}
      />
    </div>
  )
}

const IntegrationTemplate = props => {
  const { copy, images, exampleFeatureData, width } = props

  const view = calculatedView(width)
  const isDesktop = view === 'desktop'

  return (
    <div id="integrations-page" style={{ paddingTop: isDesktop ? 110 : 50 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div className={`content-container-${view}`}>
          <Introduction copy={copy} images={images} view={view} />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'black',
        }}
      >
        <div className={`content-container-${view}`}>
          <FeatureSummary
            sectionLinksHeader={copy.sectionLinksHeader}
            exampleFeatureData={exampleFeatureData}
            view={view}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div className={`content-container-${view}`}>
          <FeaturesExpanded
            exampleFeatureData={exampleFeatureData}
            view={view}
          />
        </div>
      </div>
      <FinalSection finalCopy={copy.finalCopy} view={view} />
    </div>
  )
}

IntegrationTemplate.propTypes = {
  copy: PropTypes.shape({
    header: PropTypes.string.isRequired,
    subHeader: PropTypes.string.isRequired,
    integrationExplanationHeader: PropTypes.string.isRequired,
    integrationExplanation: PropTypes.string.isRequired,
    sectionLinksHeader: PropTypes.string.isRequired,
    finalCopy: PropTypes.string.isRequired,
  }),
  images: PropTypes.shape({
    logos: PropTypes.shape({
      iris: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        childImageSharp: PropTypes.shape({
          fixed: PropTypes.shape(),
          fluid: PropTypes.shape(),
        }),
      }),
      integrationCompanyIcon: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        childImageSharp: PropTypes.shape({
          fixed: PropTypes.shape(),
          fluid: PropTypes.shape(),
        }),
      }),
    }),
    ideaMockups: PropTypes.shape({
      exportModel: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        childImageSharp: PropTypes.shape({
          fixed: PropTypes.shape(),
          fluid: PropTypes.shape(),
        }),
      }),
      vrIdea: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        childImageSharp: PropTypes.shape({
          fixed: PropTypes.shape(),
          fluid: PropTypes.shape(),
        }),
      }),
    }),
  }),
  exampleFeatureData: PropTypes.objectOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      descriptionList: PropTypes.arrayOf(PropTypes.string),
      image: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        childImageSharp: PropTypes.shape({
          fixed: PropTypes.shape(),
          fluid: PropTypes.shape(),
        }),
      }),
    })
  ),
}

export default props => {
  return (
    <Layout>
      <IntegrationTemplate {...props} />
    </Layout>
  )
}
