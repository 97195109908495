export const uppercaseKeyWords = string => {
  const lowercasedWords = ['and']
  const uppercaseWord = word => word.slice(0, 1).toUpperCase() + word.slice(1)

  return string
    .split(' ')
    .map(word => (lowercasedWords.includes(word) ? word : uppercaseWord(word)))
    .map(word => {
      if (word.split('-').length > 1) {
        return word
          .split('-')
          .map(word => uppercaseWord(word))
          .join('-')
      }

      return word
    })
    .join(' ')
}
