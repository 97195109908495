import { getWindowLocationOrigin } from '../../../utils/window'
import { generateMetaTags } from '../../../utils/helmet'

const title = 'Revit VR Plugin | Prospect by IrisVR'
const description = `Bring Revit models into VR with Prospect. Instant, true-to-scale BIM design review, model coordination, and QA/QC. Supports materials, metadata, and linked files.`
const url = `${getWindowLocationOrigin()}/revit`
const image =
  'https://s3.amazonaws.com/iris-opengraph-images/ImgTag_Quest.jpg.png'
const imageWidth = 1200
const imageHeight = 630

export default generateMetaTags({
  title,
  description,
  image,
  url,
  imageWidth,
  imageHeight,
})
