/**
 * Ensures the last word isn't by itself on the line below
 * @param {string} string
 */
export const preventOrphanedWords = string => {
  const ary = string.split(' ')

  const beginning = ary.slice(0, -2).join(' ')
  /**
   * Using \u00A0 as a replacement for &nbsp; see:
   * https://stackoverflow.com/questions/37909134/nbsp-jsx-not-working
   */
  const ending = ary.slice(-2).join('\u00A0')

  return beginning + ' ' + ending
}

/**
 * Prevents orphaned words in header copy
 */
export const sanitizedCopy = copy =>
  Object.keys(copy).reduce((acc, key) => {
    return { ...acc, [key]: preventOrphanedWords(copy[key]) }
  }, {})

/**
 * Prevents orphaned words in feature description
 */
export const sanitizedExampleFeatureData = exampleFeatureData =>
  Object.keys(exampleFeatureData).reduce((acc, key) => {
    const featureKey = exampleFeatureData[key]
    return {
      ...acc,
      [key]: {
        ...featureKey,
        description: preventOrphanedWords(featureKey.description),
      },
    }
  }, {})
